body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* 常用的布局 */
.box {
  display: flex;
}
.box_cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_vc {
  display: flex;
  align-items: center;
}
.box_bd {
  flex: 1;
}
.clearfix{
  clear: both;
}
.TemporaryJobDetail-head .ant-form-item{
  margin-bottom: 0;
}