.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-color: #f0f2f5;
}

.content {
  padding: 32px 0;
  flex: 1;
}

.top {
  text-align: center;
  margin-top: 120px;
}

.header {
  height: 44px;
  line-height: 44px;
}
header a{
  text-decoration: none;
}

.logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}

.title {
  font-size: 33px;
  color: rgba(0,0,0,.85);
  font-weight: 600;
  position: relative;
  top: 2px;
}

