body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* 常用的布局 */
.box {
  display: flex;
}
.box_cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_vc {
  display: flex;
  align-items: center;
}
.box_bd {
  flex: 1 1;
}
.clearfix{
  clear: both;
}
.TemporaryJobDetail-head .ant-form-item{
  margin-bottom: 0;
}
/* 老年人看不清楚灰色的字体 */
.ant-select-disabled,
.ant-input-disabled {
    color: black;
}
.ant-time-picker-input[disabled]{
    color: black;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-color: #f0f2f5;
}

.content {
  padding: 32px 0;
  flex: 1 1;
}

.top {
  text-align: center;
  margin-top: 120px;
}

.header {
  height: 44px;
  line-height: 44px;
}
header a{
  text-decoration: none;
}

.logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}

.title {
  font-size: 33px;
  color: rgba(0,0,0,.85);
  font-weight: 600;
  position: relative;
  top: 2px;
}


